import { useEffect } from 'react';
import ReactHelmet from 'react-helmet';
import Footer from './Footer';
import Header from './Header';

const MainLayout = ({ className, title, children }) => {
  useEffect(() => {
    if (className) {
      document.body.classList.add(className);
    }

    return () => {
      if (className) {
        document.body.classList.remove(className);
      }
    };
  }, []); //eslint-disable-line

  return (
    <>
      <ReactHelmet>
        <title>{title}</title>
        <link rel="shortcut icon" href="/favicon.png" />
      </ReactHelmet>
      <Header />
      <main className={className}>{children}</main>
      <Footer />
    </>
  );
};

export default MainLayout;
