import { useState, useEffect } from 'react';
import MCountUp from 'src/components/MCountUp/MCountUp';
import './Services.scss';

const Services = () => {
  const [familiesFed, setFamiliesFed] = useState();
  const [gallonsMilkPerDay, setGallonsMilkPerDay] = useState();
  const [gallonsMilkPerYear, setGallonsMilkPerYear] = useState();
  const [poundsCheese, setPoundsCheese] = useState();

  useEffect(() => {
    const lgApiUrl = process.env.REACT_APP_LG_API_URL;
    fetch(`${lgApiUrl}/balance`)
      .then((res) => {
        res.json().then((data) => {
          if (data) {
            const balances = data.balances;
            const numMinted = parseInt(balances.numMinted);
            setFamiliesFed(numMinted / 4);
            setGallonsMilkPerDay(numMinted * 4);
            setGallonsMilkPerYear(284 * numMinted);
            setPoundsCheese((284 * numMinted) / 1.25);
          }
        });
      })
      .catch((err) => {
        console.error({ err });
      });
  }, []);

  return (
    <section className="section-service" id="section-charity">
      <div className="container">
        <h2>We donate an actual goat every time you purchase a Lucky Goat!</h2>
        <p>
          Heifer International is a global development organization on a mission
          to end hunger and poverty in a sustainable way beginning with
          agriculture. For 76 years, Heifer has invested in more than 36 million
          smallholder farmers in 21 rural societies across Africa, Asia, and the
          Americas. Heifer has been working with communities in Bangladesh since
          the early 2000s to provide care/sustainability training, agrovets,
          tools, and goats! This project offers a financial and culturally
          invigorating future for over 35K families and needs additional
          donation support. We have pledged 10% of all profits (including post
          minting sales) to the Heifer project in Bangladesh. We are posting the
          verified transfers in our Discord, sharing progress specifics as they
          become available, and hope to continue give for years to come!
        </p>
        <a
          href="https://www.heifer.org/gift-catalog/animals/gift-of-a-goat.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        <div className="compute-result">
          <div className="compute-item">
            <h4>
              <MCountUp number={familiesFed} name={'family-fed'} />
            </h4>
            Families fed
          </div>
          <div className="compute-item">
            <h4>
              <MCountUp number={gallonsMilkPerDay} />
            </h4>
            Gallons of milk per day
          </div>
          <div className="compute-item">
            <h4>
              <MCountUp number={gallonsMilkPerYear} />
            </h4>
            Gallons of milk per year
          </div>
          <div className="compute-item">
            <h4>
              <MCountUp number={poundsCheese} />
            </h4>
            Pounds of cheese made
          </div>
        </div>
        <img src="/images/homepage/service/goat.png" alt="goat" />
        <span className='service-description'>
          (Left to right) Mohammad Mojnu Molla, 45 yrs, Mohammad Fahim Molla, 16
          yrs, Mosammat Sumaiya Khatun, 9 yrs, and Angura Begum, 34 yrs, hold
          goats at their home in Manikpur village, Bangladesh.{' '}
        </span>
      </div>
    </section>
  );
};

export default Services;
