import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  getTransactionReceipt,
  getCurrentWalletAddress,
} from 'src/utils/wallet';
import { gtmInitialize } from 'src/utils/gtm';

import Spinner from 'src/components/Spinner';
import SecondLayout from 'src/layout/SecondLayout/SecondLayout';

import './PurchaseLoading.scss';
import axios from 'axios';

const PurchaseLoading = () => {
  const lgApiUrl = process.env.REACT_APP_LG_API_URL;

  const history = useHistory();
  const { txnId } = useParams();
  const [apiTimeout, setApiTimeout] = useState();
  const [pollingSeconds, setPollingSeconds] = useState(1);

  const [familiesFed, setFamiliesFed] = useState(0);
  const [gallonsMilkPerDay, setGallonsMilkPerDay] = useState(0);
  const [gallonsMilkPerYear, setGallonsMilkPerYear] = useState(0);
  const [poundsCheese, setPoundsCheese] = useState(0);

  const fetchAPIData = async () => {
    const txnRcpt = await getTransactionReceipt(txnId);
    // Check to see if txn is false
    if (txnRcpt && txnRcpt.txnStatus === 0) {
      //log here
      fetch(
        `${lgApiUrl}/log/Transaction error: Redirecting to /txn-error/${txnId}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            txnRcpt,
            txnId,
          }),
        }
      ).catch((err) => {
        console.error({ err });
      });

      clearTimeout(apiTimeout);
      history.push(`/txn-error/${txnId}`);
      return false;
    }

    setPollingSeconds(pollingSeconds + 1);
    const tokenId = txnRcpt.tokenId;
    console.log(`Getting tokenId from rcpt ${tokenId}`);
    if (tokenId == null) {
      //log here
      fetch(`${lgApiUrl}/log/Transaction processing`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          txnRcpt,
          txnId,
        }),
      }).catch((err) => {
        console.error({ err });
      });

      return setApiTimeout(setTimeout(fetchAPIData, 5001));
    }

    if (tokenId && tokenId > 0 && txnRcpt.txnStatus === 1) {
      let query = {};
      const queryString = window.location.search;
      if (queryString !== '') {
        const urlParams = new URLSearchParams(queryString);
        for (const pair of urlParams.entries()) {
          const key = pair[0];
          const val = pair[1];
          query[key] = val;
        }
      }

      const walletAddr = await getCurrentWalletAddress();
      const txnData = {
        txnId,
        walletAddr,
        tokenId,
        query,
      };
      console.log(txnData);
      clearTimeout(apiTimeout);

      //log here
      fetch(`${lgApiUrl}/log/Attempting to create transaction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          txnRcpt,
          txnId,
          txnData,
        }),
      }).catch((err) => {
        console.error({ err });
      });

      // create a transaction record
      fetch(`${lgApiUrl}/transaction`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(txnData),
      })
        .then((res) => {
          console.log({ res });
          if (res.status >= 200) {
            //log here
            fetch(`${lgApiUrl}/log/Successfully created transaction`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                txnRcpt,
                txnId,
                txnData,
                res,
              }),
            })
              .then((res) => {
                history.push(`/goat/${tokenId}/txn/${txnId}`);
              })
              .catch((err) => {
                console.error({ err });
              });
          } else {
            console.error({ res });
            //log here
            fetch(`${lgApiUrl}/log/Error transaction api response status`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                txnRcpt,
                txnId,
                txnData,
                res,
              }),
            }).catch((err) => {
              console.error({ err });
            });
          }
        })
        .catch((err) => {
          console.error({ err });
          //log here
          fetch(`${lgApiUrl}/log/Error in transaction api call`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              txnRcpt,
              txnId,
              txnData,
              err,
            }),
          }).catch((err) => {
            console.error({ err });
          });
        });
    } else {
      //log here
      console.log(
        `Cannot get token(${tokenId}) or false txn status(${txnRcpt.status})`
      );

      //log here
      fetch(
        `${lgApiUrl}/log/Cannot get token(${tokenId}) or false txn status(${txnRcpt.status})`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            txnRcpt,
            txnId,
            tokenId,
          }),
        }
      )
        .then((_) => {
          clearTimeout(apiTimeout);
          history.push(`/txn-error/${txnId}`);
        })
        .catch((err) => {
          clearTimeout(apiTimeout);
        });
    }
  };

  const fetchBalances = async () =>
    new Promise((resolve, reject) => {
      fetch(`${lgApiUrl}/balance`)
        .then((res) => {
          res.json().then((data) => {
            if (data) {
              const balances = data.balances;
              const numMinted = parseInt(balances.numMinted);
              setFamiliesFed(numMinted / 4);
              setGallonsMilkPerDay(numMinted);
              setGallonsMilkPerYear(284 * numMinted);
              setPoundsCheese((284 * numMinted) / 1.25);
            }
            resolve(res);
          });
        })
        .catch((err) => {
          console.error({ err });
          reject(err);
        });
    });

  const sendRequest = () => {
    let searchParams = new URLSearchParams(window.location.search);
    let sendParams = new URLSearchParams();
    if (searchParams.get('clickId')) {
      sendParams.set('payout', '200');
      sendParams.set('cid', searchParams.get('clickId'));
      axios.get(`https://mint.luckygoat.org/postback?${sendParams.toString()}`);
    }
  };

  useEffect(() => {
    gtmInitialize();
    (async () => {
      await fetchBalances();
    })();
    setApiTimeout(setTimeout(fetchAPIData, 5001));
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecondLayout
      className="purchase-loading"
      title="Lucky Goat - Purchase Loading"
    >
      <div className="container">
        <div className="purchase-box">
          <h2>Your Lucky Goat is on the way!</h2>
          <Spinner />

          <h3>
            Your NFT is being minted and this page will refresh automatically
            <br />
            <br />
            Did you know that by purchasing your goat, you have also contributed
            to donating goats and agricultural aid to families in need! Thanks
            to you families now have access to:
          </h3>

          <div className="compute-result">
            <div className="compute-item">
              <h4>
                <span>{familiesFed}</span>
              </h4>
              Families fed
            </div>
            <div className="compute-item">
              <h4>
                <span>{gallonsMilkPerDay}</span>
              </h4>
              Gallons of milk per day
            </div>
            <div className="compute-item">
              <h4>
                <span>{gallonsMilkPerYear}</span>
              </h4>
              Gallons of milk per year
            </div>
            <div className="compute-item">
              <h4>
                <span>{poundsCheese}</span>
              </h4>
              Pounds of cheese made
            </div>
          </div>
        </div>
      </div>
    </SecondLayout>
  );
};

export default PurchaseLoading;
