import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const MCountUp = ({ number }) => {
  const [firstVisible, setFirstVisible] = useState(true);
  const onVisibleChange = (isVisible, start) => {
    if (isVisible) {
      if (firstVisible) {
        start();
      }
      setFirstVisible(false);
    }
  };

  return (
    <CountUp end={number} duration={2} redraw={true} separator=",">
      {({ countUpRef, start }) => (
        <VisibilitySensor
          onChange={(isVisible) => onVisibleChange(isVisible, start)}
          delayedCall
        >
          <span ref={countUpRef} />
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

export default MCountUp;
