import React, { useState } from 'react';
import './FAQItem.scss';

const FAQItem = ({ data }) => {
  const { title, content } = data;
  const [opened, setOpened] = useState(false);

  const toggle = () => {
    setOpened(!opened);
  };

  return (
    <div className={`faq-item ${opened && 'opened'}`}>
      <div className="title-bar" onClick={toggle}>
        {title}
        <span></span>
      </div>
      <div className="content">{content}</div>
    </div>
  );
};

export default FAQItem;
