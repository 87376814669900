import { useState, useEffect } from 'react';
import './MSlider.scss';

const rareTexts = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];

const MSlider = ({ rarity }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    let index = rareTexts.findIndex((text) => text === rarity);
    if (index === -1) {
      index = 0;
    }

    setPosition(2.5 + 19 * index);
  }, [rarity]);

  return (
    <div className='m-slider'>
      <div className='slider-body-outline'>
        <div className='slider-body'>
          {Array(19)
            .fill(0)
            .map((_, id) => {
              return (
                <div className='slider-body-divider' key={`divider_${id}`} />
              );
            })}
        </div>
        <div className='slider-handle' style={{ left: `${position}%` }} />
      </div>

      <span className='txt-common'>Common</span>
      <span className='txt-legend'>Legendary</span>
    </div>
  );
};

export default MSlider;
