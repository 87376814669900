import './Spinner.scss';

const Spinner = () => {
  return (
    <div className="spinner-box">
      {Array(12)
        .fill()
        .map((_, id) => {
          return <div key={`spinner-item${id}`} className="spinner-item"></div>;
        })}
    </div>
  );
};

export default Spinner;
