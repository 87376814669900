import { useState, useEffect } from 'react';

import CTAButton from 'src/components/CTAButton/CTAButton';
import './GetInWithTheGoat.scss';

const GetInWithTheGoat = (props) => {
  const { inViewport, forwardedRef } = props;
  const [firstScroll, setFirstScroll] = useState(0);

  useEffect(() => {
    if (inViewport && firstScroll === 0) {
      setFirstScroll(1);
    } else if (inViewport) {
      setFirstScroll(2);
    }
  }, [inViewport]); //eslint-disable-line

  return (
    <section
      className="section-get-in-with-the-goat"
      id="section-story"
      ref={forwardedRef}
    >
      <div className="container">
        <img
          src="/images/homepage/stars.png"
          alt="stars"
          className="img-stars"
        />
        
        <h2>Join the Lucky Goat and High Command in their story:</h2>
        <p>
          The Metaverse has come under attack! In response, all the Metaverse
          councils have gather to mount their resistance. Their first act is to
          call on The Lucky Goats to defend their citizens! Each Lucky Goat may
          be unique in their appearance, but they all share a special power of
          protection. They can unleash a brilliant beam of light that surrounds
          the Lucky Goat and its holder giving them an impenetrable shield from
          which to fight injustice. If the holder truly believes in good over
          evil, they cannot be touched during their battles against Dr Famine’s
          MetaBeasts.
          <br />
          <br /> Dr Famine (who has long envied the Metaverse with all its
          freedom, vibrance, and peaceful inhabitants) has had a stroke of mad
          genius and discovered the perfect tool to concur the Metaverse in its
          entirety. He has managed to combine the most vicious qualities of the
          Metaverse’s most evil predators into an army of MetaBeasts! He has
          unleashed them with one purpose- to destroy and control everything
          this is bright and virtuous!
          <br />
          <br /> The Lucky Goats and their holders have banded together to form
          shields around all the Metaverse’s many realities and revered
          technologies. Together they can accomplish anything, because it is the
          sacred duty of each Lucky Goat and its holder to stand for all that is
          good and just in the face of this absolute tyranny!
          <br />
          <br /> Who will join our Lucky Goats in the war against these dark and
          oppressive forces?
          <br />
          <br />
          <strong>
            Do you have what it takes?! Join our discord<br/> to follow the lore as
            it develops...
          </strong>
        </p>
        <CTAButton />
        <img
          src="/images/homepage/get-in-with-the-goat/cannon_goat.png"
          alt="goat"
          className="img-goat"
        />
      </div>
    </section>
  );
};

export default GetInWithTheGoat;
