import React from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as Logo } from 'src/icons/logo.svg';

import styles from './Footer.module.scss';
import CTAButton from 'src/components/CTAButton/CTAButton';

const footerIcons = [
  {
    linkUrl: 'https://discord.gg/luckygoat',
    imageUrl: 'discord',
    toolTip: 'Join our Discord',
  },
  {
    linkUrl: 'https://opensea.io/collection/luckygoat',
    imageUrl: 'opensea',
    toolTip: 'OpenSea Collection',
  },
  {
    linkUrl:
      'https://etherscan.io/address/0x907b5299756ca8dd27c62b621b0bc023420c6ffd',
    imageUrl: 'etherscan',
    toolTip: 'Etherscan',
  },
  {
    linkUrl: 'https://www.instagram.com/luckygoat.io/',
    imageUrl: 'instagram',
    toolTip: 'Instagram',
  },
  {
    linkUrl: 'https://twitter.com/LuckyGoat_io',
    imageUrl: 'twitter',
    toolTip: 'Twitter',
  },
];

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="left-side">
          <div>
            <a href="/">
              <Logo />
            </a>
          </div>
          <div className="cert-icon">
            <img src="/images/icons/certik.png" alt="certik" />
          </div>
          <div className="icons">
            {footerIcons.map((item) => (
              <a
                data-for="hover_tooltip_footer"
                data-tip={item.toolTip}
                key={item.linkUrl}
                href={item.linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`/images/icons/footer/${item.imageUrl}.png`}
                  alt={item.imageUrl}
                />
              </a>
            ))}
            <ReactTooltip
              id="hover_tooltip_footer"
              getContent={(dataTip) => <span>{dataTip}</span>}
              effect="solid"
              delayHide={200}
              delayShow={200}
              delayUpdate={200}
              place="bottom"
            />
          </div>
        </div>
        <div className="right-side">
          <div>
            <CTAButton />
          </div>
          <div className="links">
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
