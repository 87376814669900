import { useEffect } from 'react';
import './App.css';
// import Minter from './Minter'
import { Switch, Route } from 'react-router-dom';
import LandingPage from './pages/GoldenCannon';
import SecretPayout from './pages/SecretPayout';
import Provenance from './pages/Provenance';
import OurGoats from './pages/OurGoats';
import PurchaseLoading from './pages/PurchaseLoading/PurchaseLoading';
import TransactionError from './pages/TransactionError/TransactionError';
import PurchasePage from './pages/PurchasePage/PurchasePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const App = () => {
  const setAppHeight = () => {
    document.documentElement.style.setProperty(
      '--app-height',
      `${window.innerHeight}px`
    );
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--theme-color', '#040D19');
    window.addEventListener('resize', setAppHeight);

    return () => {
      window.removeEventListener('resize', setAppHeight);
    };
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />

      {/* Button to transfer eth out of contract and into justin's wallet */}
      <Route
        path="/super/secret/gimme/cash/gimme/ether"
        component={SecretPayout}
      />

      <Route path="/provenance" component={Provenance} />
      <Route path="/mygoats" component={OurGoats} />
      <Route path="/loading/:txnId" component={PurchaseLoading} />
      <Route path="/goat/:tokenId/txn/:txnId" component={PurchasePage} />

      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-of-service" component={TermsOfService} />

      <Route path="/txn-error/:txnId" component={TransactionError} />
    </Switch>
  );
};

export default App;
