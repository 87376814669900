import { types } from './actions';

const initialState = {
  wallet_address: undefined,
};

const wallets = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.CONNECT_TO_WALLET: {
      state = { ...state, wallet_address: payload };
      break;
    }

    default:
      break;
  }
  return state;
};

export default wallets;
