import { useEffect } from 'react';
import handleViewport from 'react-in-viewport';

import './LandingPage.scss';
import MainLayout from 'src/layout/MainLayout/MainLayout';
import WelcomeSection from './sections/Welcome';

import { gtmInitialize } from 'src/utils/gtm';
import Services from './sections/Services';
import GetInWithTheGoat from './sections/GetInWithTheGoat';
import HowItWorks from './sections/HowItWorks';
import Goats from './sections/Goats';
import Roadmap from './sections/Roadmap';
import TeamMember from './sections/TeamMembers';
import FAQGoat from './sections/FAQGoat';
import ContractAddress from './sections/ContractAddress';

const GetInWithTheGoatViewPort = handleViewport(GetInWithTheGoat);
const RoadmapViewPort = handleViewport(Roadmap);

const ArtDirection = () => {
  useEffect(() => {
    gtmInitialize();
  }, []);

  return (
    <MainLayout className="wrapper" title="Lucky Goat - Charity NFT Collection">
      <WelcomeSection />
      <Services />
      <GetInWithTheGoatViewPort />
      <HowItWorks />
      <Goats />
      <RoadmapViewPort />
      <TeamMember />
      <FAQGoat />
      <ContractAddress />
    </MainLayout>
  );
};

export default ArtDirection;
