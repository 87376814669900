import React, { useEffect, useState } from 'react';
import CTAButton from 'src/components/CTAButton/CTAButton';
import MGoatItem from 'src/components/MGoatItem/MGoatItem';
import SecondLayout from 'src/layout/SecondLayout/SecondLayout';
import { getCurrentWalletAddress } from 'src/utils/wallet';
import { gtmInitialize } from 'src/utils/gtm';

import './OurGoats.scss';

const OurGoats = () => {
  const [myGoatsNotFound, setMyGoatsNotFound] = useState(
    'Looking for your goats...'
  );
  const [myGoats, setMyGoats] = useState();
  const env = process.env.REACT_APP_APPLICATION_ENV;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const osUrl =
    env === 'prod'
      ? `https://opensea.io/assets/${contractAddress}`
      : `https://testnets.opensea.io/assets/${contractAddress}`;

  useEffect(() => {
    const fetchAPIData = async () => {
      const lgApiUrl = process.env.REACT_APP_LG_API_URL;
      const walletAddr = await getCurrentWalletAddress();
      fetch(`${lgApiUrl}/user/${walletAddr}`)
        .then((res) => {
          if (res.status === 404) {
            setMyGoatsNotFound('No goats found');
          } else {
            res.json().then((j) => {
              if (j) {
                setMyGoats(j);
              } else {
                setMyGoatsNotFound('Empty response from server');
              }
            });
          }
        })
        .catch((err) => {
          console.error({ err });
          setMyGoatsNotFound('Error occurred');
        });
    };

    gtmInitialize();
    (async () => {
      await fetchAPIData();
    })();
  }, []);

  if (Array.isArray(myGoats) && myGoats.length) {
    return (
      <SecondLayout className="mygoats" title="Lucky Goat - Our Goats">
        <>
          <div className="nav-header">
            <div className="container">
              <h2>My Goats</h2>
              <CTAButton />
            </div>
          </div>
          <div className="goat-wrapper">
            <div className="container">
              <div className="goat-list">
                {myGoats.map((goat, index) => (
                  <MGoatItem key={index} goat={goat} osUrl={osUrl} />
                ))}
              </div>
            </div>
          </div>
        </>
      </SecondLayout>
    );
  } else {
    return (
      <SecondLayout className="mygoats" title="Lucky Goat - Our Goats">
        <>
          <div className="nav-header">
            <div className="container">
              <h2>My Goats</h2>
              <CTAButton />
            </div>
          </div>
          <div className="goat-wrapper">
            <div className="container">
              <h3>{myGoatsNotFound}</h3>
            </div>
          </div>
        </>
      </SecondLayout>
    );
  }
};

export default OurGoats;
