import './MGoatItem.scss';

import { ReactComponent as RarityScore } from 'src/icons/rarity.svg';
import { ReactComponent as Time } from 'src/icons/time.svg';
import { ReactComponent as Value } from 'src/icons/value.svg';

const MGoatItem = ({ goat, osUrl }) => {
  return (
    <div className="m-goat-item">
      <div className="title">
        <span className="name">
          LUG <strong>{`#${goat.tokenId}`}</strong>
        </span>
        <span className="type">{goat.rarity.tier}</span>
      </div>
      <div className="content">
        <span>
          <RarityScore />
          Rarity Score:{' '}
          <strong>{Number(goat.rarity.rarityScore).toFixed(1)}</strong>
        </span>
        <span>
          <Time />
          Early Mint Multiplier:{' '}
          <strong>{Number(goat.rarity.earlyMintMultiplier).toFixed(2)}X</strong>
        </span>
        <span>
          <Value />
          Roughage Value: <strong>{goat.rarity.rougageValue}</strong>
        </span>
        <a href={`${osUrl}/${goat.tokenId}`} target="_blank" rel="noreferrer">
          <img src={goat.ipfsImageUrl} alt="goat" />
        </a>
      </div>
    </div>
  );
};

export default MGoatItem;
