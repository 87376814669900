import CTAButton from 'src/components/CTAButton/CTAButton';
import './Goats.scss';

const Goats = () => {
  return (
    <section className="section-goats">
      <div className="container">
        <div className="goat-list">
          <div className="goat-item">
            <img src="/images/thumbs/36.png" alt="common" />
            <div className="goat-type">Common</div>
          </div>

          <div className="goat-item">
            <img src="/images/thumbs/34.png" alt="common" />
            <div className="goat-type">Uncommon</div>
          </div>

          <div className="goat-item">
            <img src="/images/thumbs/29.png" alt="common" />
            <div className="goat-type">Rare</div>
          </div>

          <div className="goat-item">
            <img src="/images/thumbs/33.png" alt="common" />
            <div className="goat-type">Epic</div>
          </div>

          <div className="goat-item">
            <img src="/images/thumbs/30.png" alt="common" />
            <div className="goat-type">Legendary</div>
          </div>
        </div>

        <div className="left-info">
          <CTAButton />
        </div>
      </div>
    </section>
  );
};

export default Goats;
