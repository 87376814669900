
// import { clientCallMintNFT } from '../../../utils/interact'
import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom'
import CTAButton from 'src/components/CTAButton/CTAButton';

import './Welcome.scss';

const WelcomeSection = () => {
  // const history = useHistory()
  const lgApiUrl = process.env.REACT_APP_LG_API_URL;

  const [total, setTotal] = useState(0)
  const [minted, setMinted] = useState(0)
  // const onMintPressed = async () => {
  //   const queryString = window.location.search
  //   const hash = await clientCallMintNFT()
  //   if (hash !== false) {
  //     history.push(`/loading/${hash}${queryString}`)
  //   }
  // }

  useEffect(() => {
    ;(async() => {
      fetch(`${lgApiUrl}/balance`)
      .then((res) => {
        res.json().then((j) => {
          setTotal(j.balances?.totalTokens)
          setMinted(j.balances?.numMinted)
        })
      })
    })()
    
  }, [])

  return (
    <section className="section-welcome">
      <div className="container">
        <div className="welcome-title">
          <img src="/images/homepage/welcome/title.png" alt="welcome-title" />
          <h3>HIGH COMMAND</h3>
          <p>
            The MetaBeasts are mobilizing faster than the Lucky Goat High
            Command anticipated. Join our forces with a collectable Lucky Goat
            High Command NFT Protector of your own. Holders gain access to the
            Lucky Lounge where they can vote on the fate of the chosen, qualify
            for allied airdrops, earn access to top secret content, games, and
            more!
          </p>

          <div className="left-info">
            <CTAButton />
            <span>
              LUG Mint <strong>{minted}</strong> out of <strong>{total}</strong>
            </span>
          </div>
        </div>

        <img
          className="img-welcome"
          src="/images/homepage/welcome/welcome.png"
          alt="welcome"
        />
      </div>
    </section>
  );
};

export default WelcomeSection;
