import { useEffect } from 'react';
import ReactHelmet from 'react-helmet';
import Header from '../MainLayout/Header';
import Footer from '../MainLayout/Footer';

const SecondLayout = ({ className, title, children }) => {
  useEffect(() => {
    if (className) {
      document.body.classList.add(className);
    }

    return () => {
      if (className) {
        document.body.classList.remove(className);
      }
    };
  }, []); //eslint-disable-line

  return (
    <>
      <ReactHelmet>
        <title>{title}</title>
      </ReactHelmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default SecondLayout;
