export function gtmInitialize() {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WBTD586');
}

function flushPreviousValues(newDataLayer) {
  const previousDataLayer = window['previousDataLayer'] || {};

  window['previousDataLayer'] = {};
  for (let key in newDataLayer) {
    window['previousDataLayer'][key] = undefined;
  }

  return {
    ...previousDataLayer,
    ...newDataLayer,
  };
}

export function trackEvent(event, status = '') {
  const dataLayer = {
    event: event,
  };

  if (status) {
    dataLayer.wallet_status = status;
  }

  const flushedDataLayer = flushPreviousValues(dataLayer);
  window['dataLayer'].push(flushedDataLayer);

  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  } else {
    console.log(flushedDataLayer);
  }
}
