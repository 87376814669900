import React, { useEffect, useState } from 'react';
import MainLayout from 'src/layout/MainLayout/MainLayout';
import './Provenance.scss';

const Provenance = () => {
  const [openSeaUrl, setOpenSeaUrl] = useState();
  const [etherscanUrl, setEtherscanUrl] = useState();
  const [openSeaPrefix, setOpenSeaPrefix] = useState();
  const [etherscanPrefix, setEtherscanPrefix] = useState();
  const env = process.env.REACT_APP_APPLICATION_ENV;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  const lgApiUrl = process.env.REACT_APP_LG_API_URL;
  const [provenance, setProvenance] = useState();
  const header = ['Goat', 'Transaction', 'Original Owner', 'IPFS Image'];

  const fetchAPIData = () => {
    const osUrl =
      env === 'prod' ? 'https://opensea.io' : 'https://testnets.opensea.io';
    if (env === 'prod') {
      setOpenSeaUrl(`${osUrl}/collection/luckygoat`);
    } else {
      setOpenSeaUrl(`${osUrl}/collection/luckygoatnft-v4`);
    }

    setOpenSeaPrefix(osUrl);

    const eUrl =
      env === 'prod' ? 'https://etherscan.io' : 'https://rinkeby.etherscan.io';
    setEtherscanUrl(`${eUrl}/token/${contractAddress}`);
    setEtherscanPrefix(eUrl);

    fetch(`${lgApiUrl}/provenance3`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.status === 200) {
          res.json().then((jsonData) => {
            setProvenance(jsonData.provenance);
          });
        } else {
          console.error({ res });
        }
      })
      .catch((err) => {
        console.error({ err });
      });
  };

  useEffect(() => {
    fetchAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (provenance) {
    return (
      <MainLayout className="goat-wrapper" title="Lucky Goat - Provenance">
        <div className="container">
          <h2>Lucky Goat’s Provenance Record</h2>
          <span className="sub-title">
            This page presents the provenance record of all minted Lucky Goats
          </span>
          <div className="icon-container">
            <div>
              <a href={etherscanUrl} target="_blank" rel="noreferrer">
                <img src="/images/Goat/etherscan.png" alt="etherscan" />
                Etherscan Token
              </a>
            </div>
            <div>
              <a href={openSeaUrl} target="_blank" rel="noreferrer">
                <img src="/images/Goat/opensea.png" alt="opensea" />
                Opensea Listing
              </a>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {header.map((h, i) => (
                    <th key={i}>{h}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(provenance).map((k, i) => {
                  let data = provenance[k];
                  let tokenUrl = `${openSeaPrefix}/assets/${contractAddress}/${data.tokenId}`;
                  let txnUrl = `${etherscanPrefix}/tx/${data.txnId}`;
                  let walletUrl = `${etherscanPrefix}/address/${data.walletAddr}`;
                  let ipfsImageUrl = data.ipfsImageUrl;
                  let ipfsHash = ipfsImageUrl
                    ? ipfsImageUrl.split('/').pop()
                    : null;

                  return (
                    <tr key={i}>
                      <td>
                        <a href={tokenUrl} target="_blank" rel="noreferrer">
                          {data.tokenId}
                        </a>
                      </td>
                      <td>
                        <a href={txnUrl} target="_blank" rel="noreferrer">
                          {data.txnId}
                        </a>
                      </td>
                      <td>
                        <a href={walletUrl} target="_blank" rel="noreferrer">
                          {data.walletAddr}
                        </a>
                      </td>
                      <td>
                        <a href={ipfsImageUrl} target="_blank" rel="noreferrer">
                          {ipfsHash}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </MainLayout>
    );
  } else {
    return (
      <MainLayout className="goat-wrapper" title="Lucky Goat - Provenance">
        <div className="container">
          <h2>Lucky Goat’s Provenance Record</h2>
          <span className="sub-title">Please wait...</span>
        </div>
      </MainLayout>
    );
  }
};

export default Provenance;
