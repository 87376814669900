import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { showWeb3WalletModal, getCurrentWalletAddress } from 'src/utils/wallet';
import './CTAButton.scss';
import { clientCallMintNFT } from '../../utils/wallet';
import { trackEvent } from 'src/utils/gtm';

const CTAButton = () => {
  const history = useHistory();
  const price = process.env.REACT_APP_NFT_ETH_PRICE;
  const lgApiUrl = process.env.REACT_APP_LG_API_URL;
  const [showMintButton, setShowMintButton] = useState(false);

  const fetchAPIData = () => {
    setShowMintButton(true);

    // fetch(`${lgApiUrl}/balance`, {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json' },
    // })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       res.json().then((jsonData) => {
    //         if (jsonData.balances.numMinted <= 2000) {
    //           setShowMintButton(true);
    //         } else {
    //           setShowMintButton(false);
    //         }
    //       });
    //     } else {
    //       console.error({ res });
    //     }
    //   })
    //   .catch((err) => {
    //     console.error({ err });
    //   });
  };

  useEffect(() => {
    fetchAPIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMintPressed = async () => {
    const acct = await getCurrentWalletAddress();
    if (!acct) {
      showWeb3WalletModal().then(() => {
        window.location.reload();
      });
      trackEvent('mint_button', 'disconnected');
    } else {
      trackEvent('mint_button', 'connected');
    }

    const queryString = window.location.search;
    await clientCallMintNFT((err, txnId) => {
      if (err) {
        console.error(err);
      }
      if (txnId) {
        history.push(`/loading/${txnId}${queryString}`);
      }
    });
  };

  return (
    <div className="button-cta">
      <div className="button-wrapper">
        <div className="price">
          <span>Current Price:</span>
          <strong>{price} ETH</strong>
        </div>
        <button onClick={onMintPressed}>Mint Now</button>
      </div>
    </div>
  );
};

export default CTAButton;
