import './HowItWorks.scss';

const collectionData = [
  '11 Furs',
  '6 Heads',
  '12 Mouths',
  '8 Outfits',
  '8 Eyes',
  '9 Accessories',
  '14 Backgrounds',
];

const HowItWorks = () => {
  return (
    <section className="section-how-it-works" id="section-collection">
      <div className="container">
        <h2>The Lucky Goat NFT Collection</h2>

        <img
          src="/images/homepage/collection/work-background.png"
          alt="line"
          className="img-background"
        />
        <img
          src="/images/homepage/collection/work-goat.png"
          alt="goat"
          className="img-goat"
        />
        <div className="collection-mobile">
          <div className="work-screen-container">
            <img src="/images/homepage/collection/work-screen.png" alt="goat" />
          </div>
          <div className="collection-list">
            {collectionData.map((data) => {
              return (
                <div key={data} className="collection-item">
                  <img
                    src="/images/homepage/collection/smart-object-rect.png"
                    alt="background"
                    className="background"
                  />
                  <div className="collection-item-content">
                    <img
                      src="/images/homepage/collection/checkicon.png"
                      alt="checkicon"
                    />
                    {data}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
