import SecondLayout from 'src/layout/SecondLayout/SecondLayout';
import './TermsOfService.scss';

const TermsOfService = () => {
  return (
    <SecondLayout
      className="terms-of-service"
      title="Lucky Goat - Terms of Service"
    >
      <img src="/images/support-background.png" alt="background" />

      <div className="container">
        <h2>Terms Of Service</h2>
        <div className="terms-of-service-box">
          <strong>
            Please read these Terms of Use ("Terms", "Terms of Use") carefully
            before using the QuickMedigap.com website (the "Service") operated
            by Optimize.Ad Inc. ("us", "we", or "our").
          </strong>
          <br />
          <br /> Your access to and use of the Service is conditioned upon your
          acceptance of and compliance with these Terms without modification.
          These Terms apply to all visitors, users and others who wish to access
          or use the Service.
          <br />
          <br /> By accessing or using the Service you agree to be bound by
          these Terms, whether or not you have read them. If you disagree with
          any part of the terms then you do not have permission to access the
          Service.
          <br />
          <br />
          <span></span>
          <br />
          <br />
          <strong> Purchases </strong>
          <br />
          <br />
          If you wish to purchase any data made available through the Service
          ("Purchase"), you may be asked to supply certain information relevant
          to your Purchase including, without limitation, your credit card
          number, the expiration date of your credit card, your billing address,
          and your shipping information.
          <br />
          <br /> You represent and warrant that: (i) you have the legal right to
          use any credit card(s) or other payment method(s) in connection with
          any Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
          <br />
          <br /> The service may employ the use of third party services for the
          purpose of facilitating payment and the completion of Purchases. By
          submitting your information, you grant us the right to provide the
          information to these third parties subject to our Privacy Policy.
          <br />
          <br /> You acknowledge and agree that Quick Medigap does not operate
          or control the internet or mobile networks and that (i) viruses,
          worms, Trojan horses or other undesirable data or software, or (ii)
          unauthorized users may attempt to obtain access to and damage your
          data, websites, hardware or networks. Service shall not be responsible
          for such activities.
          <br />
          <br /> We reserve the right to refuse or cancel your order at any time
          for reasons including but not limited to: product or service
          availability, errors in the description or price of the product or
          service, error in your order or other reasons.
          <br />
          <br /> All data is sold "as is," "where is," and "with all faults,"
          and, client shall have no right to request a refund. The Service will
          allow for a credit to your Quick Medigap account of up to 10% of the
          cost for data you have purchased that is proven to be a disconnected
          phone number or a phone number that is not in service.
          <br />
          <br /> We reserve the right to refuse or cancel your order if fraud or
          an unauthorized or illegal transaction is suspected.
          <br /> Availability, Errors and Inaccuracies
          <br />
          <br /> We are constantly updating product and service offerings on the
          Service. We may experience delays in updating information on the
          Service and in our advertising on other web sites. The information
          found on the Service may contain errors or inaccuracies and may not be
          complete or current. Products or services may be mispriced, described
          inaccurately, or unavailable on the Service and we cannot guarantee
          the accuracy or completeness of any information found on the Service.
          <br />
          <br />
          We therefore reserve the right to change or update information and to
          correct errors, inaccuracies, or omissions at any time without prior
          notice. <br />
          <br />
          <br />
          <strong>We use cookies to:</strong>
          <br />
          <br /> Help remember and process the items in the shopping cart.
          <br />
          Understand and save user’s preferences for future visits.
          <br /> Keep track of advertisements.
          <br /> TCompile aggregate data about site traffic and site
          interactions in order to offer better site experiences and tools in
          the future. We may also use trusted third party services that track
          this information on our behalf.
          <br /> You can choose to have your computer warn you each time a
          cookie is being sent, or you can choose to turn off all cookies. You
          do this through your browser (like Internet Explorer) settings. Each
          browser is a little different, so look at your browser’s Help menu to
          learn the correct way to modify your cookies.
          <br />
          <br /> If you disable cookies off, some features will be disabled It
          won’t affect the users experience that make your site experience more
          efficient and some of our services will not function properly.
          <br />
          <br />
          However, you can still place orders.
          <br />
          <br />
          <br />
          <strong>
            {' '}
            Please read these Terms of Use ("Terms", "Terms of Use") carefully
            before using the QuickMedigap.com website (the "Service") operated
            by Optimize.Ad Inc. ("us", "we", or "our").
          </strong>
          <br />
          <br /> Your access to and use of the Service is conditioned upon your
          acceptance of and compliance with these Terms without modification.
          These Terms apply to all visitors, users and others who wish to access
          or use the Service.
          <br />
          <br /> By accessing or using the Service you agree to be bound by
          these Terms, whether or not you have read them. If you disagree with
          any part of the terms then you do not have permission to access the
          Service.
          <br />
          <br /> Contests, Sweepstakes and Promotions
          <br />
          <br /> Any contests, sweepstakes or other promotions (collectively,
          "Promotions") made available through the Service may be governed by
          rules that are separate from these Terms Conditions. If you
          participate in any Promotions, please review the applicable rules as
          well as our Privacy Policy. If the rules for a Promotion conflict with
          these Terms of Use, the Promotion rules will apply.
          <br />
          <br />
          <br />
          <strong> Accounts</strong>
          <br /> When you create an account with us, you guarantee that you are
          above the age of 18, and that the information you provide to us is
          accurate, complete, and current at all times. Inaccurate, incomplete,
          or obsolete information may result in the immediate termination of
          your account on the Service.
          <br />
          <br /> You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You accept responsibility for
          all activities or actions that occur under your account and/or
          password, whether your password is with our Service or a third-party
          service. You must notify us immediately upon becoming aware of any
          breach of security or unauthorized use of your account.
          <br />
          <br /> You may not use as a username the name of another person or
          entity or that is not lawfully available for use, a name or trademark
          that is subject to any rights of another person or entity other than
          you, without appropriate authorization. You may not use as a username
          any name that is offensive, vulgar or obscene.
          <br />
          <br /> We reserve the right to refuse service, terminate accounts,
          remove or edit content, or cancel orders in our sole discretion.
          <br />
          <br />
          <br />
          <strong>Intellectual Property</strong>
          <br /> The Service and its original content, features and
          functionality are and will remain the exclusive property of
          Optimize.Ad Inc. and its licensors. The Service is protected by
          copyright, trademark, and other laws of both the United States and
          foreign countries. Our trademarks and trade dress may not be used in
          connection with any product or service without the prior written
          consent of Optimize.Ad Inc.
          <br />
          <br />
          <br />
          <br />
          <strong> Links to Other Web Sites</strong>
          <br /> Our Service may contain links to third party web sites or
          services that are not owned or controlled by Optimize.Ad Inc. When you
          link to those sites, you leave the Quick Medigap site. Optimize.Ad
          Inc. has no control over, and assumes no responsibility for the
          content, resources, privacy policies, or practices of any third-party
          web sites or services. We do not warrant the offerings of any of these
          entities/individuals or their websites. Our Privacy Policy does not
          apply to the practices of any companies or individuals operating the
          linked sites.
          <br />
          <br /> You acknowledge and agree that Optimize.Ad Inc. shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such third party web sites or services.
          <br />
          <br /> We strongly advise you to read the terms and conditions and
          privacy policies of any third party web sites or services that you
          visit. Your use of such third party sites or services is at your own
          risk.
          <br />
          <br />
          <br />
          <strong> Termination</strong>
          <br /> We may terminate or suspend your account and bar access to the
          Service immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms. We may also
          exercise all other legal and equitable remedies that may be available.
          <br />
          <br /> If you wish to terminate your account, you may simply
          discontinue using the Service.
          <br />
          <br /> All provisions of the Terms which by their nature should
          survive termination shall survive termination, including, without
          limitation, ownership provisions, warranty disclaimers,
          indemnification and limitations of liability.
          <br />
          <br />
          <br />
          <strong> Indemnification</strong>
          <br /> You agree to defend, indemnify and hold harmless Optimize.Ad
          Inc. and its licensee and licensors, and their employees, contractors,
          agents, officers and directors, from and against any and all claims,
          damages, obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, by you or any
          person using your account and password, or b) a breach of these Terms.
          <br />
          <br />
          <br />
          <strong>Limitation Of Liability</strong>
          <br /> In no event shall Optimize.Ad Inc., nor its directors,
          employees, partners, agents, suppliers, or affiliates, be liable for
          any indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed its essential purpose.
          <br />
          <br /> In no event shall Quick Medigap’s total liability for all
          damages exceed the amount paid by you for the Service within the one
          year period prior to the alleged act or omission giving rise to Quick
          Medigap’s alleged liability.
          <br />
          <br /> You agree to bring any and all claims and actions against Quick
          Medigap no more than one (1) year after the date of the alleged act or
          omission giving rise to Quick Medigap's liability or be forever
          barred. Disclaimer Your use of the Service is at your sole risk. The
          Service is provided on an "AS IS" and "AS AVAILABLE" basis. The
          Service is provided without warranties of any kind, whether express or
          implied, including, but not limited to, implied warranties of
          merchantability, fitness for a particular purpose, non-infringement or
          course of performance.
        </div>
      </div>
    </SecondLayout>
  );
};

export default TermsOfService;
