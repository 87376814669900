import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';

import {
  showWeb3WalletModal,
  getCurrentWalletAddress,
  disconnectWallet,
} from 'src/utils/wallet';
import { trackEvent } from 'src/utils/gtm';
import { ReactComponent as Logo } from 'src/icons/logo.svg';

// import { connectWallet } from 'src/store/wallets/actions'
import styles from './Header.module.scss';

const Header = () => {
  const history = useHistory();
  const [connectBtnTxt, setConnectBtnTxt] = useState('CONNECT YOUR WALLET');
  const [menuOpened, setMenuOpened] = useState(false);
  const [myGoatsLink, setMyGoatsLink] = useState('');

  // const connectedStatus = useSelector((state) => state.wallets)
  const [connectedStatus, setConnectedStatus] = useState(false);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  useEffect(() => {
    const doWalletStuff = async () => {
      const acct = await getCurrentWalletAddress();
      // const acct = false
      if (acct) {
        // dispatch(connectWallet(String(acct)))
        const btnTxt =
          `Connected: ` +
          String(acct).substring(0, 6) +
          '...' +
          String(acct).substring(38);
        setConnectBtnTxt(btnTxt);
        setConnectedStatus(true);
        setMyGoatsLink(
          <li>
            <a href="/mygoats">My Goats</a>
          </li>
        );
      }
    };
    doWalletStuff();
  }, []);

  const connectShowWeb3WalletModal = () => {
    if (connectedStatus) {
      disconnectWallet().then(() => {
        window.location.reload();
      });
      trackEvent('disconnect_wallet');
    } else {
      showWeb3WalletModal().then(() => {
        window.location.reload();
      });
      trackEvent('connect_wallet');
    }
  };

  const onLink = async (e, url, id) => {
    e.preventDefault();

    await history.push(url);

    $('html, body').animate(
      {
        scrollTop: $(`#${id}`).offset().top,
      },
      800
    );
  };

  return (
    <header className={`${styles.header}`}>
      <div className="container">
        <div className="logo">
          <a href="/">
            <Logo />
          </a>
        </div>
        <nav className="main-menu">
          <ul>
            <li>
              <a
                href="/"
                onClick={(e) => onLink(e, '/', 'section-story')}
              >
                Our Story
              </a>
            </li>
            <li>
              <a
                href="/"
                onClick={(e) => onLink(e, '/', 'section-charity')}
              >
                Charity
              </a>
            </li>
            <li>
              <a
                href="/"
                onClick={(e) => onLink(e, '/', 'section-collection')}
              >
                NFT Collection
              </a>
            </li>
            <li>
              <a href="/provenance">Provenance</a>
            </li>
            {myGoatsLink}
          </ul>
        </nav>
        <div className="btn-container">
          <button
            className={`btn btn-green ${connectedStatus && 'wallet-connected'}`}
            onClick={connectShowWeb3WalletModal}
          >
            <p id="wallet-msg">{connectBtnTxt}</p>
          </button>
        </div>

        <div className="mobile-links">
          <img
            src="/images/icons/wallet.png"
            alt="wallet"
            className="img-wallet"
          />

          <nav
            className={`mobile-menu-wrap ${menuOpened && 'opened'}`}
            onClick={toggleMenu}
          >
            <span className="cls"></span>
            <span className="cls"></span>
            <span className="cls"></span>
            <ul className="mobile-menu">
              <li>
                <button
                  className="btn-link"
                  onClick={() => onLink('/', 'section-story')}
                >
                  Our Story
                </button>
              </li>
              <li>
                <button
                  className="btn-link"
                  onClick={() => onLink('/', 'section-charity')}
                >
                  Charity
                </button>
              </li>
              <li>
                <button
                  className="btn-link"
                  onClick={() => onLink('/', 'section-collection')}
                >
                  NFT Collection
                </button>
              </li>
              <li>
                <a href="/provenance">Provenance</a>
              </li>
              {myGoatsLink}
              <li>
                <button
                  className={`btn btn-green ${
                    connectedStatus && 'wallet-connected'
                  }`}
                  onClick={connectShowWeb3WalletModal}
                >
                  <p>{connectBtnTxt}</p>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
