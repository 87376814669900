import './EmailButton.scss';
import { useState } from 'react';
import { getCurrentWalletAddress } from '../../utils/wallet';

const EmailButton = () => {
  const lgApiUrl = process.env.REACT_APP_LG_API_URL;
  const [btnText, setBtnText] = useState('Submit');

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    getCurrentWalletAddress()
      .then((walletAddr) => {
        const email = event.target.email.value;
        if (!email || email === '') {
          alert('Please enter email address');
          return false;
        }
        fetch(`${lgApiUrl}/user/collect-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ walletAddr, email }),
        })
          .then((res) => {
            setBtnText('Submitted');
          })
          .catch((err) => {
            console.error({ err });
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <form onSubmit={handleEmailSubmit}>
      <div className="button-email">
        <div className="button-wrapper">
          <div className="email">
            <input placeholder="Enter Email" type="email" name="email" />
          </div>
          <button type="submit">{btnText}</button>
        </div>
      </div>
    </form>
  );
};

export default EmailButton;
