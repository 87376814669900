import React, { useState } from 'react';
import MainLayout from 'src/layout/MainLayout/MainLayout';
import './SecretPayout.scss';
import { clientCallWithdraw } from 'src/utils/interact';

const SecretPayout = (props) => {
  const [txnHash, setTxnHash] = useState('');

  const onWithdrawPressed = async () => {
    const hash = await clientCallWithdraw();
    setTxnHash(hash);
  };

  return (
    <MainLayout className="goat-wrapper" title="GoatPage">
      <section className="goat-section">
        <div className="goat-dialog">
          <button className="btn btn-lightgreen" onClick={onWithdrawPressed}>
            <span>
              <strong>Transfer funds from goat contract</strong>
            </span>
          </button>
        </div>
        <br />
        <br />
        <div>TXN Hash: {txnHash}</div>
      </section>
    </MainLayout>
  );
};

export default SecretPayout;
