import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { gtmInitialize } from 'src/utils/gtm';
import EmailButton from 'src/components/EmailButton/EmailButton';
import MGoatItem from 'src/components/MGoatItem/MGoatItem';
import MSlider from 'src/components/MSlider/MSlider';
import SecondLayout from 'src/layout/SecondLayout/SecondLayout';
import './PurchasePage.scss';

const PurchasePage = () => {
  const lgApiUrl = process.env.REACT_APP_LG_API_URL;
  const [goat, setGoat] = useState({});
  const [openSeaUrl, setOpenSeaUrl] = useState();
  const [etherscanUrl, setEtherscanUrl] = useState();

  const { tokenId, txnId } = useParams();
  const lugApiUrl = process.env.REACT_APP_LG_API_URL;
  const env = process.env.REACT_APP_APPLICATION_ENV;
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

  const fOsUrl = env === 'prod' ? `https://opensea.io/assets/${contractAddress}` : `https://testnets.opensea.io/assets/${contractAddress}`;

  const loadGoatJson = () => {
    const osUrl =
      env === 'prod' ? 'https://opensea.io' : 'https://testnets.opensea.io';
    setOpenSeaUrl(`${osUrl}/assets/${contractAddress}/${tokenId}`);

    const eUrl =
      env === 'prod' ? 'https://etherscan.io' : 'https://rinkeby.etherscan.io';
    setEtherscanUrl(`${eUrl}/tx/${txnId}`);

    fetch(`${lugApiUrl}/transaction/${txnId}`)
      .then((response) => {
        // https://datatracker.ietf.org/doc/html/rfc4918#section-11.4
        if (response.status === 424) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          return response.json();
        }
      })
      .then((txn) => {
        if (txn) {
          setGoat(txn);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    //log here
    fetch(`${lgApiUrl}/log/Goat confirmation page`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tokenId,
        txnId,
      }),
    }).catch((err) => {
      console.error({ err });
    });

    gtmInitialize();
    loadGoatJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SecondLayout
      className='purchase-page'
      title='Lucky Goat - Purchase Successful'
    >
      <div className='container'>
        <div className='purchase-dialog'>
          <h4>Congratulations on your new Lucky Goat!</h4>
          <div className="img-section">
            {goat.ipfsImageUrl ? (
              <MGoatItem goat={goat} osUrl={fOsUrl}/>
            ) : (
              <img src="/images/load-loading.gif" alt="goat" />
            )}
          </div>
          <div className="info-section">
            <div className="info-container">
              <h3>Congratulations on your new Lucky Goat!</h3>
              <MSlider rarity={goat.rarity?.tier} />
              <p>View on:</p>
              <a
                className="btn btn-opensea"
                href={openSeaUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Goat/opensea.png" alt="opensea" />
                OpenSea
              </a>
              <a
                className="btn btn-etherscan"
                href={etherscanUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/images/Goat/etherscan.png" alt="etherscan" />
                Etherscan
              </a>
            </div>
          </div>
        </div>
        <h5>Enter your email for Lucky Goat updates:</h5>
        <EmailButton />
      </div>
    </SecondLayout>
  );
};

export default PurchasePage;
