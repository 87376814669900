import ReactTooltip from 'react-tooltip';
import FAQItem from 'src/components/FAQItem/FAQItem';
import './FAQGoat.scss';

const faqData = [
  {
    title: 'What is an NFT?',
    content:
      'An NFT or Non-fungible Token, is a uniquely coded unit of data, usually a piece of art that you can buy to keep or trade. This piece of art and all of its ownership data (maker, first buyer, all trade info) is stored within a blockchain on IPFS.',
  },
  {
    title: 'What is blockchain?',
    content:
      'Blockchain is basically a digital ledger that includes all transaction information for an NFT or piece of cryptocurrency that is written and stored in such a way that it is impossible to change or alter. Blockchain can also be found in games that use cryptocurrency or NFTs for in game purchases or trades.',
  },
  {
    title: 'What makes NFTs rare or valuable?',
    content:
      'There are only 10,420 Lucky Goat NFTs available in the world, making this international community tight knit and exclusive. While NFTs create a new way to make money, very few also contribute to a greater cause like we do with the Heifer Charity (we donate 25% of every NFT purchased).',
  },
  {
    title: 'What makes an individual NFT rare?',
    content:
      'NFTs are created using a unique and random combination of factors like fur, background, face, accessories, etc. While every NFT is one of a kind, certain combinations will contain an accessory, background, or feature that can only be seen in a very small percentage of a NFT collection.',
  },
  {
    title: 'What can I do with my Lucky Goat?',
    content:
      ' You can do whatever you like such as trade, sell, or keep your Lucky Goat which has a non-exclusive license. Your Lucky Goat also acts as a membership token for our exclusive and ever-growing Lucky Goat community content.',
  },
];

const footerIcons = [
  {
    linkUrl: 'https://discord.gg/luckygoat',
    imageUrl: 'discord',
    toolTip: 'Join our Discord',
  },
  {
    linkUrl: 'https://opensea.io/collection/luckygoat',
    imageUrl: 'opensea',
    toolTip: 'OpenSea Collection',
  },
  {
    linkUrl:
      'https://etherscan.io/address/0x907b5299756ca8dd27c62b621b0bc023420c6ffd',
    imageUrl: 'etherscan',
    toolTip: 'Etherscan',
  },
  {
    linkUrl: 'https://www.instagram.com/luckygoat.io/',
    imageUrl: 'instagram',
    toolTip: 'Instagram',
  },
  {
    linkUrl: 'https://twitter.com/LuckyGoat_io',
    imageUrl: 'twitter',
    toolTip: 'Twitter',
  },
];

const FAQGoat = () => {
  return (
    <section className="section-faq">
      <div className="container">
        <h2>Join the Lucky Goat herd</h2>
        <img
          src="/images/homepage/stars.png"
          alt="stars"
          className="img-stars"
        />
        <div className="icons">
          {footerIcons.map((item) => (
            <a
              data-for="hover_tooltip"
              data-tip={item.toolTip}
              key={item.linkUrl}
              href={item.linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`/images/icons/footer/${item.imageUrl}.png`}
                alt={item.imageUrl}
              />
            </a>
          ))}
          <ReactTooltip
            id="hover_tooltip"
            getContent={(dataTip) => <span>{dataTip}</span>}
            effect="solid"
            delayHide={200}
            delayShow={200}
            delayUpdate={200}
            place="bottom"
          />
        </div>
        <div className="faq-list">
          {faqData.map((item) => {
            return <FAQItem key={item.title} data={item} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default FAQGoat;
