import React, { useState, useEffect } from 'react';

import './Roadmap.scss';
import { ReactComponent as Arrow } from 'src/icons/arrow.svg';

const textContent = [
  {
    percent: '11%',
    text: `First crowd funded donation of Goats, watch the gift giving get real.`,
  },
  {
    percent: '22%',
    text: `Gain access to the Goat Hall of Honor. Here you can start earning Goat
    Rewards, based on how rare your goat is and how many goats you own.`,
  },
  {
    percent: '33%',
    text: `Special sneak peak to the Goat High Command NFT. The first 500 will be
    airdropped to random Lucky Goat owners.`,
  },
  {
    percent: '44%',
    text: `Exclusive Goat merch release including Ts, pins, and horns/polles/scurs of your own.`,
  },
  {
    percent: '55%',
    text: `Initiation and launch of the Goat shop. Here you can use your Goat Rewards to
    play games, or trade them for Goats.`,
  },
  {
    percent: '88%',
    text: `Release of the MetaBeasts, for those who love to love the bad guys! Includes a fan fiction origin story for the nefarious Dr Famine and his own exclusive NFT which will be airdropped to the 100th MetaBeast buyer.`,
  },
  {
    percent: '100%',
    text: ` Launch of the first Lucky Goat game, a place where you can compete for Lucky
    Goat rewards.`,
  },
];
const Roadmap = (props) => {
  const { inViewport, forwardedRef } = props;
  const [firstScroll, setFirstScroll] = useState(0);

  useEffect(() => {
    if (inViewport && firstScroll === 0) {
      setFirstScroll(1);
    } else if (inViewport) {
      setFirstScroll(2);
    }
  }, [inViewport]); //eslint-disable-line

  return (
    <section className="section-roadmap" ref={forwardedRef}>
      <div className="container">
        <h2>Our Roadmap</h2>
        <img
          src="/images/homepage/stars.png"
          alt="stars"
          className="img-stars"
        />
        {textContent.map((item) => {
          return (
            <div
              key={item.percent}
              className={`roadmap-progress-item ${
                firstScroll === 2 && 'showed'
              } ${firstScroll === 1 && inViewport && 'animation'}`}
            >
              <div className="roadmap-item">
                <div>{item.percent}</div>
                <span>{item.text}</span>
              </div>
              <Arrow />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Roadmap;
