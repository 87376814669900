import SecondLayout from 'src/layout/SecondLayout/SecondLayout';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <SecondLayout
      className="privacy-policy"
      title="Lucky Goat - Privacy Policy"
    >
      <img src="/images/support-background.png" alt="background" />

      <div className="container">
        <h2>Privacy Policy</h2>
        <div className="policy-box">
          This privacy policy has been compiled to better serve those who are
          concerned with how their ‘Personally identifiable information’ (PII)
          is being used online. PII, as used in US privacy law and information
          security, is information that can be used on its own or with other
          information to identify, contact, or locate a single person, or to
          identify an individual in context. Please read our privacy policy
          carefully to get a clear understanding of how we collect, use, protect
          or otherwise handle your Personally Identifiable Information in
          accordance with our website.
          <br />
          <br />
          <span></span>
          <br />
          <br />
          <br />
          <strong>
            What personal information do we collect from the people that visit
            our blog, website or app?
          </strong>
          <br />
          <br />
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your name, email address, mailing address, phone
          number, credit card information or other details to help you with your
          experience.
          <br />
          <br />
          <br />
          <strong>When do we collect information?</strong>
          <br />
          <br /> We collect information from you when you register on our site,
          place an order, subscribe to a newsletter, respond to a survey, fill
          out a form or enter information on our site
          <br /> <br />
          <br />
          <strong>How do we use your information?</strong>
          <br />
          <br /> We may use the information we collect from you when you
          register, make a purchase, sign up for our newsletter, respond to a
          survey or marketing communication, surf the website, or use certain
          other site features in the following ways:
          <br />
          <br /> To personalize user’s experience and to allow us to deliver the
          type of content and product offerings in which you are most
          interested. <br />
          <ul>
            <li>To improve our website in order to better serve you.</li>
            <li>
              {' '}
              To allow us to better service you in responding to your customer
              service requests.
            </li>{' '}
            <li>
              To administer a contest, promotion, survey or other site feature.
            </li>{' '}
            <li>To quickly process your transactions.</li>{' '}
            <li>
              To send periodic emails regarding your order or other products and
              services.
            </li>
          </ul>
          <br />
          <br />
          <strong> How do we protect visitor information?</strong>
          <br />
          <br /> We do not use vulnerability scanning and/or scanning to PCI
          standards. We do not use Malware Scanning. <br />
          <br /> Your personal information is contained behind secured networks
          and is only accessible by a limited number of persons who have special
          access rights to such systems, and are required to keep the
          information confidential. In addition, all sensitive/credit
          information you supply is encrypted via Secure Socket Layer (SSL)
          technology. <br />
          <br />
          We implement a variety of security measures when a user places an
          order enters, submits, or accesses their information to maintain the
          safety of your personal information.
          <br />
          <br /> All transactions are processed through a gateway provider and
          are not stored or processed on our servers. <br />
          <br />
          <br />
          <br />
          <strong>We use cookies to:</strong>
          <br />
          <br /> Help remember and process the items in the shopping cart.
          <br />
          Understand and save user’s preferences for future visits.
          <br /> Keep track of advertisements.
          <br /> TCompile aggregate data about site traffic and site
          interactions in order to offer better site experiences and tools in
          the future. We may also use trusted third party services that track
          this information on our behalf.
          <br /> You can choose to have your computer warn you each time a
          cookie is being sent, or you can choose to turn off all cookies. You
          do this through your browser (like Internet Explorer) settings. Each
          browser is a little different, so look at your browser’s Help menu to
          learn the correct way to modify your cookies.
          <br />
          <br /> If you disable cookies off, some features will be disabled It
          won’t affect the users experience that make your site experience more
          efficient and some of our services will not function properly.
          <br />
          <br />
          However, you can still place orders. <br />
          <br />
          <br />
          <br />
          <strong>Additional Information:</strong>
          <br />
          <br />
          <u>Third Party Disclosure</u>
          <br /> We do not sell, trade, or otherwise transfer to outside parties
          your personally identifiable information unless we provide you with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or servicing you, so long as those parties agree to keep
          this information confidential. We may also release your information
          when we believe release is appropriate to comply with the law, enforce
          our site policies, or protect ours or others’ rights, property, or
          safety.
          <br />
          <br /> However, non-personally identifiable visitor information may be
          provided to other parties for marketing, advertising, or other uses.{' '}
          <br />
          <br />
          <u>Third Party Links</u>
          <br /> Occasionally, at our discretion, we may include or offer third
          party products or services on our website. These third party sites
          have separate and independent privacy policies. We therefore have no
          responsibility or liability for the content and activities of these
          linked sites. Nonetheless, we seek to protect the integrity of our
          site and welcome any feedback about these sites. <br />
          <br />
          <u>Google</u>
          <br /> Google’s advertising requirements can be summed up by Google’s
          Advertising Principles. They are put in place to provide a positive
          experience for users. Check here
          <br />
          <br /> We use Google AdSense Advertising on our website.
          <br />
          <br /> Google, as a third party vendor, uses cookies to serve ads on
          our site. Google’s use of the DART cookie enables it to serve ads to
          our users based on their visit to our site and other sites on the
          Internet. Users may opt out of the use of the DART cookie by visiting
          the Google ad and content network privacy policy. <br />
          <br />
          <u>We have implemented the following:</u>
          <br /> Remarketing with Google AdSense
          <br /> Demographics and Interests Reporting
          <br /> We along with third-party vendors, such as Google use
          first-party cookies (such as the Google Analytics cookies) and
          third-party cookies (such as the DoubleClick cookie) or other
          third-party identifiers together to compile data regarding user
          interactions with ad impressions, and other ad service functions as
          they relate to our website. <br />
          <br />
          <u>Opting out:</u>
          <br />
          Users can set preferences for how Google advertises to you using the
          Google Ad Settings page. Alternatively, you can opt out by visiting
          the Network Advertising initiative opt out page or permanently using
          the Google Analytics Opt Out Browser add on. <br />
          <br />
          <u>California Online Privacy Protection Act</u>
          <br /> CalOPPA is the first state law in the nation to require
          commercial websites and online services to post a privacy policy. The
          law’s reach stretches well beyond California to require a person or
          company in the United States (and conceivably the world) that operates
          websites collecting personally identifiable information from
          California consumers to post a conspicuous privacy policy on its
          website stating exactly the information being collected and those
          individuals with whom it is being shared, and to comply with this
          policy. <br />
          <br />
          <u>According to CalOPPA we agree to the following:</u>
          <br /> Users can visit our site anonymously Once this privacy policy
          is created, we will add a link to it on our home page, or as a minimum
          on the first significant page after entering our website. Our Privacy
          Policy link includes the word ‘Privacy’, and can be easily be found on
          the page specified above. <br />
          <br />
          <u>Users will be notified of any privacy policy changes:</u>
          <br />
          On our Privacy Policy Page <br />
          <br />
          <u>Users are able to change their personal information:</u>
          <br /> By logging in to their account <br />
          <br />
          <u>How does our site handle do not track signals?</u>
          <br /> We honor do not track signals and do not track, plant cookies,
          or use advertising when a Do Not Track (DNT) browser mechanism is in
          place. <br />
          <br />
          <u>Does our site allow third party behavioral tracking?</u>
          <br /> It’s also important to note that we do not allow third party
          behavioral tracking <br />
          <br />
          <u>COPPA (Children Online Privacy Protection Act)</u>
          <br />
          When it comes to the collection of personal information from children
          under 13, the Children’s Online Privacy Protection Act (COPPA) puts
          parents in control. The Federal Trade Commission, the nation’s
          consumer protection agency, enforces the COPPA Rule, which spells out
          what operators of websites and online services must do to protect
          children’s privacy and safety online.
          <br />
          <br /> We do not specifically market to children under 13. <br />
          <br />
          <u>Fair Information Practices</u>
          <br /> The Fair Information Practices Principles form the backbone of
          privacy law in the United States and the concepts they include have
          played a significant role in the development of data protection laws
          around the globe. Understanding the Fair Information Practice
          Principles and how they should be implemented is critical to comply
          with the various privacy laws that protect personal information.
          <br />
          <br /> In order to be in line with Fair Information Practices we will
          take the following responsive action, should a data breach occur:
          <br /> We will notify the users via email
          <br />
          <br /> Within 7 business days
          <br /> We also agree to the individual redress principle, which
          requires that individuals have a right to pursue legally enforceable
          rights against data collectors and processors who fail to adhere to
          the law. This principle requires not only that individuals have
          enforceable rights against data users, but also that individuals have
          recourse to courts or a government agency to investigate and/or
          prosecute non-compliance by data processors. <br />
          <br />
          <u>CAN SPAM Act</u>
          <br /> The CAN-SPAM Act is a law that sets the rules for commercial
          email, establishes requirements for commercial messages, gives
          recipients the right to have emails stopped from being sent to them,
          and spells out tough penalties for violations.
          <br />
          <br /> We collect your email address in order to:
          <br /> Send information, respond to inquiries, and/or other requests
          or questions.
          <br /> Process orders and to send information and updates pertaining
          to orders
          <br /> We may also send you additional information related to your
          product and/or service.
          <br /> Market to our mailing list or continue to send emails to our
          clients after the original transaction has occurred <br />
          <br />
          <u>To be in accordance with CANSPAM we agree to the following:</u>
          <br /> NOT use false, or misleading subjects or email addresses
          <br />
          Identify the message as an advertisement in some reasonable way
          <br />
          Include the physical address of our business or site headquarters
          <br />
          Monitor third party email marketing services for compliance, if one is
          used.
          <br /> Honor opt-out/unsubscribe requests quickly
          <br /> Allow users to unsubscribe by using the link at the bottom of
          each email
          <br /> If at any time you would like to unsubscribe from receiving
          future emails, you can:
          <br />
          <br /> Follow the instructions at the bottom of each email and we will
          promptly remove you from ALL correspondence. <br />
          <br />
          <br />
          <br />
          <strong>Contact Us</strong>
          <br />
          <br /> If there are any questions regarding this privacy policy you
          may contact us using the information below
          <br />
          <br /> Quick Medigap 600 Congress Avenue Austin, Texas 78704 United
          States of America
        </div>
      </div>
    </SecondLayout>
  );
};

export default PrivacyPolicy;
