import CTAButton from 'src/components/CTAButton/CTAButton';
import './TeamMember.scss';

const TeamMember = () => {
  return (
    <section className="section-teammember">
      <div className="container">
        <img
          src="/images/homepage/team/background.png"
          alt="background"
          className="img-background"
        />
        <h2>Meet the team</h2>
        <div className="team-container-desktop">
          <img src="/images/homepage/team/teams.png" alt="teams" />
        </div>
        <div className="team-container-mobile">
          <img
            src="/images/homepage/team/justin.png"
            className="img-justin"
            alt="justin"
          />
          <img
            src="/images/homepage/team/alex.png"
            className="img-alex"
            alt="justin"
          />
          <img
            src="/images/homepage/team/alan.png"
            className="img-alan"
            alt="justin"
          />
          <img
            src="/images/homepage/team/sarah.png"
            className="img-sarah"
            alt="justin"
          />
          <img
            src="/images/homepage/team/james.png"
            className="img-james"
            alt="justin"
          />
          <img
            src="/images/homepage/team/octavio.png"
            className="img-octavio"
            alt="justin"
          />
          <img
            src="/images/homepage/team/samir.png"
            className="img-samir"
            alt="justin"
          />
        </div>
        <CTAButton />
      </div>
    </section>
  );
};

export default TeamMember;
