import './ContractAddress.scss';

const address = '0x907b5299756ca8dd27c62b621b0bc023420c6ffd';
const ContractAddress = () => {
  return (
    <div className="container">
      <div className="contract-address">
        Verified Smart Contract Address:{' '}
        <a
          href={`https://etherscan.io/address/${address}`}
          target="_blank"
          rel="noreferrer"
        >
          {address}
        </a>
      </div>
    </div>
  );
};

export default ContractAddress;
